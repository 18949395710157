.TextBox {
  font-size: 1.8rem !important;
  font-family: "Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", monospace;
  word-wrap: break-word;
  width: 756px;
  min-height: 150px;
}

.TextBox:focus {
  box-shadow: 2px 2px 3px var(--boxshadow-1), -2px -2px 3px var(--boxshadow-2);
}

.Text__Activate {
  font-size: 1.1rem;
  margin-top: 1rem;
  text-align: center;
}

.Word {
word-wrap: break-word;
}
.Letter {
  opacity: 0.5;
}

.Letter--typed {
  opacity: 0.8;
}

.Letter--Blur {
  color: transparent;
  text-shadow: 0 0 5px var(--letter-blur);
  opacity: 0.5;
}
.Letter--Correct {
  opacity: 1;
}
.Letter--Incorrect {
  color: red;
  opacity: 0.6;
}
.Letter--Active {
  border-bottom: 3px solid var(--secondary-color);
  animation: blink 0.5s infinite;
}
@keyframes blink {
  100% {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
}

.caps-lock-text {
  font-size: 0.9rem;
  color: red;
  text-align: start;
  min-height: 1rem;
}

.CursorHidden {
  cursor: none !important;
}

.Result--Fallback {
  font-size: 1.3rem;
  animation: 1.8s infinite heartbeat;
}
