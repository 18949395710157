.highlight--correct {
  fill: var(--secondary-color);
  animation: type cubic-bezier(0.19, 1, 0.22, 1) 1;
}

.highlight--incorrect {
  fill: red;
  animation: type cubic-bezier(0.19, 1, 0.22, 1) 1;
}

.highlight--correct--text {
  fill: var(--primary-color);
}

.highlight--special {
  fill: #7b8cde;
  animation: type cubic-bezier(0.19, 1, 0.22, 1) 1;
}

@keyframes type {
  100% {
    fill: #f5f0f0;
    opacity: 1;
  }
}
