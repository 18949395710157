:root {
  --primary-color: #f5f0f0;
  --secondary-color: #26243d;
  --boxshadow-1: #c4c0c0;
  --boxshadow-2: #ffffff;
  --letter-blur: #000000;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  /* --toastify-color-dark: #121212; */
  --toastify-color-dark: #26243d;
  --toastify-color-light: #f5f0f0;
  --toastify-text-color-light: #26243d;
  --toastify-text-color-dark: #f5f0f0;
  --toastify-color-progress-dark: #f5f0f0;
}
[data-theme="Dark"] {
  --primary-color: #26243d;
  --secondary-color: #f5f0f0;
  --boxshadow-1: #1e1d31;
  --boxshadow-2: #2e2b49;
  --letter-blur: #ffffff;
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
