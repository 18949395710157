.Home__Wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home {
  display: grid;
  grid-template-areas: "Settings Main Profile";
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  height: 100%;
  max-width: 1440px;
}

.Home__Settings {
  grid-area: Settings;
}

.Home__Main {
  grid-area: Main;
}

.Home__Profile {
  grid-area: Profile;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home__Main__Container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* Reusable Classes */
.Button {
  font-size: 1.1rem;
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
  background: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 0.5rem;
}
.Button--Active {
  box-shadow: 2px 2px 3px var(--boxshadow-1), -2px -2px 3px var(--boxshadow-2);
}

.Button--Inactive {
  box-shadow: inset 2px 2px 3px var(--boxshadow-1),
    inset -2px -2px 3px var(--boxshadow-2);
}

@media screen and (min-height: 700px) {
  .Home {
    height: 700px;
  }
}

@media screen and (max-width: 768px) {
  .Home {
    grid-template-areas: "Profile";
    grid-template-columns: 1fr;
    max-height: 100vh;
  }
  .Home__Profile {
    grid-area: Profile;
  }
  .Home__Main {
    display: none;
  }
  .Home__Settings {
    display: none;
  }
  .DesktopHidden {
    display: block;
    color: var(--primary-color);
    background: var(--secondary-color);
  }
}
