.Settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Settings__Modes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30%;
}

.Settings__Time {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15%;
}

.Settings__Difficulty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40%;
}

.Settings__Options {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15%;
}
